// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_switch_account_title__koVN6 {
  font-size: 14px;
  color: black;
}
.Sidebar_switch_account__FfaUl {
  border-radius: 12px;
  padding: 12px 0px 0px 0px;
}
.Sidebar_restrodiv__S3QpL {
  border-radius: 12px;
  padding: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/Sidebar.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,mBAAmB;EACnB,YAAY;AACd","sourcesContent":[".switch_account_title {\n  font-size: 14px;\n  color: black;\n}\n.switch_account {\n  border-radius: 12px;\n  padding: 12px 0px 0px 0px;\n}\n.restrodiv {\n  border-radius: 12px;\n  padding: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch_account_title": `Sidebar_switch_account_title__koVN6`,
	"switch_account": `Sidebar_switch_account__FfaUl`,
	"restrodiv": `Sidebar_restrodiv__S3QpL`
};
export default ___CSS_LOADER_EXPORT___;
