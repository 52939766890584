// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewOrder_image__thF1N {
  width: 70px;
  object-fit: cover;
  border-radius: 60px;
}
.NewOrder_icon__CrEFT {
  color: red;
  border-radius: 50%;
  width: 15px;
  object-fit: cover;
  font-size: 20px;
}
.NewOrder_bottomCart__ZR1fv {
  background-color: white;
  box-shadow: 0px 1px 10px #00000078;
  position: fixed;
  height: auto;
  top: 0;
  width: 100%;
}
.NewOrder_setCustomerDialog__4ql0I,
.NewOrder_setWaiterDialog__UNC82 {
  background-color: lightcyan;
}
@media screen and (max-width: 600px) {
  .NewOrder_productList__bwtsh {
    margin-top: 90px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Order/NewOrder.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,uBAAuB;EACvB,kCAAkC;EAClC,eAAe;EACf,YAAY;EACZ,MAAM;EACN,WAAW;AACb;AACA;;EAEE,2BAA2B;AAC7B;AACA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".image {\n  width: 70px;\n  object-fit: cover;\n  border-radius: 60px;\n}\n.icon {\n  color: red;\n  border-radius: 50%;\n  width: 15px;\n  object-fit: cover;\n  font-size: 20px;\n}\n.bottomCart {\n  background-color: white;\n  box-shadow: 0px 1px 10px #00000078;\n  position: fixed;\n  height: auto;\n  top: 0;\n  width: 100%;\n}\n.setCustomerDialog,\n.setWaiterDialog {\n  background-color: lightcyan;\n}\n@media screen and (max-width: 600px) {\n  .productList {\n    margin-top: 90px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `NewOrder_image__thF1N`,
	"icon": `NewOrder_icon__CrEFT`,
	"bottomCart": `NewOrder_bottomCart__ZR1fv`,
	"setCustomerDialog": `NewOrder_setCustomerDialog__4ql0I`,
	"setWaiterDialog": `NewOrder_setWaiterDialog__UNC82`,
	"productList": `NewOrder_productList__bwtsh`
};
export default ___CSS_LOADER_EXPORT___;
