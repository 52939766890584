// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button1 {
  color: #ffff;
}

.button2 {
  border: 1px solid #d31823;
  border-radius: 5px;
  size: 50px;
  font-weight: bold;
  color: #d31823;
}
.button2:hover {
  box-shadow: 3px 3px 5px rgb(160, 164, 165);
  background-color: #d31823;
  color: #fff;
}
.gray {
  color: gray;
}
.gray-bold {
  color: gray;
  font-weight: bold;
}

.green-border {
  border: 2px solid green;
  animation: blink 2s;
  animation-iteration-count: 5;
}
@keyframes blink {
  50% {
    border-color: #fa8322;
  }
}
.green-color {
  color: green;
}

.select-account-role-dialog.bs-popover-end > .popover-arrow::after {
  border-right-color: var(--bs-popover-header-bg);
}

.const-height {
  height: 500px;
}

.mobileMenuToggler {
  display: none;
}
@media screen and (max-width: 990px) {
  .mobileMenuToggler {
    display: block;
  }
  .phUserName {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,UAAU;EACV,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,0CAA0C;EAC1C,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,4BAA4B;AAC9B;AACA;EACE;IACE,qBAAqB;EACvB;AACF;AACA;EACE,YAAY;AACd;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;AACA;EACE;IACE,cAAc;EAChB;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".button1 {\n  color: #ffff;\n}\n\n.button2 {\n  border: 1px solid #d31823;\n  border-radius: 5px;\n  size: 50px;\n  font-weight: bold;\n  color: #d31823;\n}\n.button2:hover {\n  box-shadow: 3px 3px 5px rgb(160, 164, 165);\n  background-color: #d31823;\n  color: #fff;\n}\n.gray {\n  color: gray;\n}\n.gray-bold {\n  color: gray;\n  font-weight: bold;\n}\n\n.green-border {\n  border: 2px solid green;\n  animation: blink 2s;\n  animation-iteration-count: 5;\n}\n@keyframes blink {\n  50% {\n    border-color: #fa8322;\n  }\n}\n.green-color {\n  color: green;\n}\n\n.select-account-role-dialog.bs-popover-end > .popover-arrow::after {\n  border-right-color: var(--bs-popover-header-bg);\n}\n\n.const-height {\n  height: 500px;\n}\n\n.mobileMenuToggler {\n  display: none;\n}\n@media screen and (max-width: 990px) {\n  .mobileMenuToggler {\n    display: block;\n  }\n  .phUserName {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
